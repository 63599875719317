import {IRcTheme} from "@rocketcodeit/react-components";

export const rcTheme: IRcTheme = {
    fluentUi: {
        palette: {
            themePrimary: '#2f7fbe',
            themeDark: '#2f7fbe',
        },
    },
    rc: {
        logo: {
            text: "EDILCASSA BASILICATA",
            color: "#000",
        },
        headerBar: {
            disabled: false,
            backgroundColor: "#2f7fbe",
        },
        authRocket: {
            logo: {
                path: "/logo.png"
            },
        }
    }
};
