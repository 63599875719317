import {RcApplicationMenu} from "@rocketcodeit/react-components";
import React from "react";

export const applicationMenu: RcApplicationMenu[] = [
    {
        id: "dashboard",
        title: "Stato comunicazioni",
        icon: "Home",
        routerLink: "/",
        hasIndex: true,
        component: React.lazy(() => import("../pages/dashboard/Dashboard")),
        visible: true,
        enableRoute: true,
        isPublic: false,
        children: [
            {
                id: "dashboard.detail",
                title: "Dettaglio",
                icon: "Dettaglio",
                routerLink: "/detail/:communicationId/:companyId",
                hasIndex: true,
                component: React.lazy(() => import("../pages/dashboard/detail/Detail")),
                visible: false,
                enableRoute: true,
                isPublic: false,
            }
        ]
    },
    {
        id: "import",
        title: "Importa",
        icon: "Upload",
        routerLink: "/import",
        hasIndex: true,
        component: React.lazy(() => import("../pages/import/ImportPage")),
        visible: true,
        enableRoute: true,
        isPublic: false,
    },
    {
        id: "export",
        title: "Esporta",
        icon: "Export",
        routerLink: "/export",
        hasIndex: true,
        component: React.lazy(() => import("../pages/export/Export")),
        visible: true,
        enableRoute: true,
        isPublic: false,
    },
    {
        id: "company-update",
        title: "Aggiornamento",
        icon: "Upload",
        routerLink: "/company/:id",
        hasIndex: true,
        component: React.lazy(() => import("../pages/customer/Customer")),
        visible: false,
        enableRoute: user => {
            return user?.mail === undefined;
        },
        isPublic: true,
        layout: 'blank'
    }
];
