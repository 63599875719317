import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { initializeIcons } from "@fluentui/react/lib/Icons";
import {rcTheme} from "./config/theme";
import {RcAppAuthTypes, RcReactApp} from "@rocketcodeit/react-components";
import {applicationMenu} from "./config/applicationMenu";
import 'devextreme/dist/css/dx.light.css';

import itMessages from 'devextreme/localization/messages/it.json';
import { locale, loadMessages } from 'devextreme/localization';

initializeIcons();

loadMessages(itMessages);
locale('it');

ReactDOM.render(
    <RcReactApp searchBarCustomSearches={[]} theme={rcTheme} menu={applicationMenu} authType={RcAppAuthTypes.rocket} />,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
